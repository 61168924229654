<template>
  <div class="my-page-payment my-page" :class="{skeleton:!state.loaded}">
    <div class="wrapper">
      <div class="content">
        <div class="sum font-sm">
          <div class="wrapper">
            <div class="inner">
              <div class="name font-lg">
                <span>나의 포인트</span>
              </div>
              <div class="point"></div>
              <div class="desc">
                <span>프로젝트 참여 시 이용할 수 있는 포인트입니다.</span>
              </div>
              <div class="actions">
                <button class="btn btn-light font-sm" @click="withdraw()" :disabled="state.sum === 0">출금 요청</button>
              </div>
            </div>
            <div class="side">
              <div>
                <div class="text">
                  <b>잔여 포인트</b>
                </div>
                <div class="font-md">
                  <span>{{ $lib.getNumberFormat(state.sum) }}P</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="history" v-if="state.history.length">
          <div class="title">
            <span>포인트 내역</span>
          </div>
          <div class="wrapper" v-for="(p1, idx) in state.history" :key="idx">
            <div class="key">
              <i class="fa mr-2 fa-calendar-check-o"></i>
              <span>{{ p1.key }}</span>
            </div>
            <div class="row" :class="{ plus: p2.amount > 0 }" v-for="(p2, idx2) in p1.value" :key="idx2">
              <div class="col-lg-3 date">
                <i class="fa mr-2 fa-clock-o"></i>
                <span>{{ p2.time }}</span>
              </div>
              <div class="col-lg-6 text">
                <div class="reason">
                  <span>{{ p2.reason }}</span>
                </div>
                <div class="memo" v-if="p2.memo">
                  <span>{{ p2.memo }}</span>
                </div>
              </div>
              <div class="col-lg-3 amount">
                <span>{{ (p2.amount > 0 ? "+" : "") + $lib.getNumberFormat(p2.amount) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageMyPagePoint";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "포인트");
      load();
    });

    const state = reactive({
      sum: "",
      history: [],
      loaded: false
    });

    const withdraw = () => {
      store.commit("openModal", {
        name: "CashWithdraw",
        callback: `${component.name}.load`,
      });
    };

    const load = () => {
      state.history.push({
        key: "Wait a moment",
        value: []
      });

      for (let i = 0; i < 5; i += 1) {
        state.history[0].value.push({
          time: "Please wait",
          reason: "Please wait a moment",
          amount: 1000
        });
      }

      state.loaded = false;
      http.get("/api/cash/sum").then(({data}) => {
        state.sum = data.body;

        http.get("/api/cash").then(({data}) => {
          state.loaded = true;
          state.history = [];

          data.body.forEach((h) => {
            const datetime = h.createDate.split(" ");
            const date = datetime[0].split("-").map(d => window.Number(d).toString()).join("-").replace("-", "년 ").replace("-", "월 ") + "일";
            const time = datetime[1].substring(0, 5);
            const map = state.history.find(p => p.key === date);

            if (!map) {
              state.history.push({
                key: date,
                value: [{...h, time}]
              });
            } else {
              map.value.push({...h, time});
            }
          });
        });
      });
    };

    return {component, state, load, withdraw};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-payment {
  .subject {
    margin-bottom: $px10;
  }

  .content {
    .status {
      text-align: center;
      margin-bottom: $px25;

      .input-group {
        input {
          height: $px60;
          padding: 0 $px15;
          line-height: $px48;
        }

        .input-group-append {
          width: $px80;

          button {
            padding: 0 $px15;
            width: 100%;
          }
        }
      }
    }

    .sum {
      padding-top: $px10;

      > .wrapper {
        border: $px1 solid #eee;
        padding: $px25 25% $px25 $px25;
        border-radius: $px4;
        position: relative;

        > .inner {
          position: relative;

          .name {
            font-weight: 500;
          }

          .point {
            position: absolute;
            top: $px1;
            right: 0;
          }

          .desc {
            padding-top: $px15;
          }

          .actions {
            padding-top: $px25;

            button {
              padding: $px10 $px15;
              margin-right: $px10;
            }
          }
        }

        > .side {
          background: $colorBackground;
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 25%;
          border-radius: 0 $px4 $px4 0;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-left: $px1 solid #eee;
          flex-wrap: wrap;

          .text {
            margin-bottom: $px3;
            font-weight: 300;
          }
        }
      }
    }

    .history {
      padding: $px50 $px25;

      .title {
        font-size: $px20;
        font-weight: 500;
      }

      > .wrapper {
        padding: $px20 0;

        .key {
          padding-bottom: $px5;
        }

        .row {
          border-bottom: 1px solid #eee;
          padding: $px17 0;

          > div {
            border: 0;

            &.text {
              .memo {
                font-size: $px14;
                color: #666;
                padding-top: $px1;
              }
            }

            &.amount {
              text-align: right;
            }
          }

          &.plus {
            > div.amount {
              color: $colorAnchor;
            }
          }
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  &.skeleton {
    .content {
      .sum > .wrapper {
        > .inner {
          .name > span, .desc > span, .actions button {
            @include skeleton;
          }
        }

        > .side {
          @include skeleton;

          > div > div > span {
            @include skeleton;
          }
        }
      }

      .history {
        .title span {
          @include skeleton;
        }

        > .wrapper {
          i {
            display: none;
          }

          span {
            @include skeleton;
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    .content {
      .status .input-group .input-group-append {
        width: $px70;
      }

      .sum > .wrapper {
        padding: $px25 $px25 $px110 $px25;

        > .side {
          border-top: $px1 solid #eee;
          border-radius: 0 0 $px4 $px4;
          top: auto;
          bottom: 0;
          height: auto;
          width: 100%;
          padding: $px15 0;
        }
      }

      .history {
        padding-left: $px15;
        padding-right: $px15;

        .row {
          margin-top: 0;

          > div {
            &.text {
              padding-top: $px10;
              padding-bottom: $px10;
            }

            &.amount {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
</style>